import { Visit } from "../api/__generated__";
import { addLeadingZero } from "../util/add-leading-zero";

type VisitorTimeProps = {
  visit: Visit;
};

type ActualAndExpectedTimeProps = {
  actual?: string;
  expected?: string;
};

const dateTimeHourMinutes = (dateTime: string | undefined) => {
  if (dateTime) {
    const dateObject = new Date(dateTime);
    return `${addLeadingZero(dateObject.getHours())}:${addLeadingZero(
      dateObject.getMinutes()
    )}`;
  }
  return "";
};

const ActualAndExpectedTime = ({
  actual,
  expected,
}: ActualAndExpectedTimeProps) => {
  if (actual) return <>{actual}</>;
  if (expected)
    return (
      <span className="px-2 py-1 rounded-md text-center bg-yellow-light text-yellow-dark">
        {expected}
      </span>
    );

  return <></>;
};

export const VisitorTime = ({ visit }: VisitorTimeProps) => {
  const checkinTime = dateTimeHourMinutes(visit.checkInTime);
  const checkOutTime = dateTimeHourMinutes(visit.checkOutTime);
  const expectedCheckInTime = dateTimeHourMinutes(visit.expectedCheckInTime);
  const expectedCheckOutTime = dateTimeHourMinutes(visit.expectedCheckOutTime);

  return (
    <>
      <ActualAndExpectedTime
        actual={checkinTime}
        expected={expectedCheckInTime}
      />
      {` - `}
      <ActualAndExpectedTime
        actual={checkOutTime}
        expected={expectedCheckOutTime}
      />
    </>
  );
};
