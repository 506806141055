import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import { Loading } from "../../components/loading";
import { NoAccess } from "../../components/no-access";
import { PageTemplateManagement } from "../../components/page-template-management";
import { Table } from "../../components/table";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { userHasAccess } from "../../util/check-user-access";
import { Visit, userRole } from "../../api/__generated__/types";

import {
  useManagementCheckinExpected,
  useManagementCheckout,
  useManagementGetVisits,
} from "../../api/__generated__/hooks";
import { ReactComponent as Today } from "../../svg/today.svg";
import { createDateString } from "../../util/create-date-string";
import { Option } from "../../types/reactSelectOption";
import { ModalAddVisitor } from "../../components/modal-add-visitor";
import { CustomDatePicker } from "../../components/custom-date-picker";
import { LocationFilter } from "../../components/location-filter";
import { useLocations } from "../../hooks/use-locations";
import { VisitorTime } from "../../components/visitor-time";

export const VisitorManagement = () => {
  const { state, dispatch } = useAppData();
  const [isLoading, setIsLoading] = useState(true);
  const todayFullString = createDateString(new Date());
  const [dayFullString, setDayFullString] = useState(todayFullString);
  const { inProgress } = useMsal();
  useMsalAuthentication(InteractionType.Redirect);
  const [visits, setVisits] = useState<Visit[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const { data: clientLocations } = useLocations();
  const [showVisitorModal, setShowVisitorModal] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);

  const {
    data: visitsData,
    isLoading: loading,
    error: visitorsError,
    refetch: refetchVisitors,
  } = useManagementGetVisits({
    "day-start": dayFullString,
    "day-end": dayFullString,
  });

  useEffect(() => {
    dispatch({
      type: actions.SET_DATE,
      payload: currentDate,
    });
  }, [dispatch, currentDate]);

  useEffect(() => {
    if (
      state.profile &&
      (state.profile.role === userRole.VISITOR_MANAGEMENT ||
        state.profile.role === userRole.ADMIN)
    ) {
      setIsLoading(loading);

      if (visitsData) {
        setVisits(visitsData);
      }
      if (visitorsError) {
        console.error(visitorsError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Current visitors could not be retrieved, please try again later.",
          },
        });
      }
    }
  }, [dispatch, loading, state.profile, visitsData, visitorsError]);

  const [locationFilter, setLocationFilter] = useState(
    localStorage.getItem("location") || ""
  );

  const isCheckedIn = (visit: Visit) =>
    visit && visit.checkInTime && !visit.checkOutTime;

  const isPlanned = (visit: Visit) =>
    visit && visit.expectedCheckInTime && !visit.checkInTime;

  const shouldShowActionButton = userHasAccess(state.profile, [userRole.ADMIN]);

  const handleVisitorSearch = (date: Date | null) => {
    if (date) setCurrentDate(date);
    const dateFullString = date ? createDateString(date) : "";
    setDayFullString(dateFullString);
  };

  const checkOutVisitor = useManagementCheckout({
    mutation: {
      onSuccess: () => {
        refetchVisitors();
      },
      onError: (error) => {
        console.error("Check out failed:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Visitor could not be checked out.",
          },
        });
      },
    },
  });

  const checkInVistor = useManagementCheckinExpected({
    mutation: {
      onSuccess: () => {
        refetchVisitors();
      },
      onError: (error) => {
        console.error("Check in failed:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Visitor could not be checked in.",
          },
        });
      },
    },
  });

  const getVisitorStatus = (visit: Visit) => {
    if (isCheckedIn(visit)) {
      return <Badge variant="success">Checked in</Badge>;
    }

    if (isPlanned(visit)) {
      return <Badge variant="info">Planned</Badge>;
    }

    return <Badge variant="error">Checked out</Badge>;
  };

  const handleSelectedLocation = (option: Option | null) => {
    if (!option) return;
    setLocationFilter(option.value);
    localStorage.setItem("location", option.value);
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && visitsData) {
      setVisits(visitsData);
    }
  }, [inProgress, visitsData]);

  if (
    !userHasAccess(state.profile, [userRole.VISITOR_MANAGEMENT, userRole.ADMIN])
  ) {
    return state.profile ? <NoAccess /> : <Loading />;
  }

  const filteredVisits = visits.filter(
    (visit) => visit.location === localStorage.getItem("location")
  );

  return (
    <PageTemplateManagement error={state.error}>
      {clientLocations && (
        <div className="absolute top-0 self-end mt-10 w-48">
          <LocationFilter
            clientLocations={clientLocations}
            handleSelectedLocation={handleSelectedLocation}
            value={locationFilter}
          />
        </div>
      )}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div className="mb-4 md:mb-0 md:mr-4 ">
          <div className="flex flex-row">
            <CustomDatePicker
              isSmall
              selectedDate={currentDate}
              onChange={(date: Date | null) => handleVisitorSearch(date)}
            />
            <Button
              isSmall
              variant={"link"}
              onPointerDown={() => handleVisitorSearch(new Date())}
              className="no-underline flex flex-row self-end gap-0.5 pb-2"
            >
              <Today className="self-center w-4 ml-5" />
              Today
            </Button>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <Button
            isSmall
            variant="link"
            onPointerDown={() => {
              setShowAppointmentModal(true);
              setShowVisitorModal(false);
            }}
            className="py-2 no-underline text-primary-blue"
          >
            + Add appointment
          </Button>

          <Button
            isSmall
            variant="primary"
            className="py-2 px-2 w-48"
            onPointerDown={() => {
              setShowVisitorModal(true);
              setShowAppointmentModal(false);
            }}
          >
            + Check a visitor in
          </Button>
        </div>

        {showAppointmentModal && (
          <ModalAddVisitor
            isAppointment
            onClose={() => {
              refetchVisitors();
              setShowAppointmentModal(false);
            }}
            title={"Add appointment"}
            submitButtonText={"Add appointment"}
          />
        )}

        {showVisitorModal && (
          <ModalAddVisitor
            onClose={() => {
              refetchVisitors();
              setShowVisitorModal(false);
            }}
            title={"Check in visitor"}
            submitButtonText={"Check in"}
          />
        )}
      </div>
      {isLoading && <p className="text-xl mt-6">Loading...</p>}

      {filteredVisits.length > 0 && locationFilter && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Organization</th>
              <th>Appointment with</th>
              <th>Location</th>
              <th>Status</th>
              <th>
                Time{" "}
                <span className="px-2 py-1 rounded-md text-center font-medium bg-yellow-light text-yellow-dark">
                  Expected
                </span>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {visits
              .filter((visit) => visit.location === locationFilter)
              .map((visit, indexVisit) => (
                <tr key={indexVisit}>
                  <td>{visit.visitor.name}</td>
                  <td>{visit.visitor.email}</td>
                  <td>{visit.visitor.organization}</td>
                  <td>{visit.appointmentWith}</td>
                  <td className={"capitalize"}>
                    {visit.location
                      .replace(/_/g, " ")
                      .replace(/campus\s*/i, "")
                      .toLowerCase()}
                  </td>
                  <td>{getVisitorStatus(visit)}</td>
                  <td>
                    <VisitorTime visit={visit} />
                  </td>
                  <td>
                    {shouldShowActionButton && visit.id && (
                      <>
                        {isCheckedIn(visit) && (
                          <Button
                            isSmall
                            variant="link"
                            className={
                              "py-2 px-4 w-24 whitespace-nowrap font-semibold text-red hover:text-red-dark"
                            }
                            onPointerDown={() =>
                              checkOutVisitor.mutate({
                                visitId: visit?.id ? visit.id : "",
                              })
                            }
                          >
                            Check out
                          </Button>
                        )}

                        {isPlanned(visit) && (
                          <Button
                            isSmall
                            variant="link"
                            className={
                              "py-2 px-4 w-24 whitespace-nowrap text-primary-blue font-semibold hover:text-primary-darkBlue"
                            }
                            onPointerDown={() =>
                              checkInVistor.mutate({
                                visitId: visit?.id ? visit.id : "",
                              })
                            }
                          >
                            Check in
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      {!locationFilter && <p className={"mt-6"}>Please select a campus</p>}

      {filteredVisits.length === 0 && locationFilter && !isLoading && (
        <p className={"mt-6"}>No visitors found on {dayFullString}</p>
      )}
    </PageTemplateManagement>
  );
};
