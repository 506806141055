import React, { useReducer, ReactNode, Dispatch } from "react";
import { StateType } from "../types/stateType";
import { Action, actions } from "../types/actions";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface Store {
  state: StateType;
  dispatch: Dispatch<Action>;
}

export const AppContext = React.createContext<Store>({} as Store);

interface AppProviderProps {
  children: ReactNode;
}

const reducer = (state: StateType, action: Action): StateType => {
  switch (action.type) {
    case actions.SET_CLIENT_CHECKIN_NEW:
      return {
        ...state,
        clientCheckInNew: action.payload,
      };
    case actions.SET_RECOGNIZED_VISITOR:
      return {
        ...state,
        recognizedVisitor: action.payload,
      };
    case actions.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actions.SET_CAPTURED_IMAGE:
      return {
        ...state,
        capturedImage: action.payload,
      };
    case actions.TOGGLE_FETCH_CURRENT_VISITORS:
      return {
        ...state,
        toggleFetchCurrentVisitors: action.payload,
      };
    case actions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actions.SET_QR_DATA:
      return {
        ...state,
        qrData: action.payload,
      };
    case actions.SET_ROLES: {
      if (action.payload === null) {
        return state;
      }
      return {
        ...state,
        roles: action.payload,
      };
    }
    case actions.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case actions.SET_IO_ORGANIZERS:
      return {
        ...state,
        organizers: action.payload,
      };
    case actions.SET_CLIENT_SESSION:
      return {
        ...state,
        clientSession: action.payload,
      };
    case actions.SET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case actions.SET_EXPECTED_CHECKOUTS:
      return {
        ...state,
        expectedCheckouts: action.payload,
      };
    case actions.SET_CHECKED_OUT_VISITOR:
      return {
        ...state,
        checkedOutVisitor: action.payload,
      };
    case actions.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case actions.SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const initialState: StateType = {
    clientCheckInNew: null,
    recognizedVisitor: null,
    error: null,
    capturedImage: null,
    toggleFetchCurrentVisitors: false,
    clients: null,
    users: null,
    roles: null,
    profile: null,
    organizers: null,
    qrData: null,
    clientSession: null,
    expectedCheckouts: null,
    checkedOutVisitor: null,
    date: undefined,
    toast: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ state, dispatch }}>
        {children}
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
