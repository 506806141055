import { actions } from "../types/actions";
import { useAppData } from "../hooks/use-app-data";
import { Organizer, useManagementCreateVisit } from "../api/__generated__";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "./button";
import { Input } from "./input";
import { validationRules } from "../util/form-validation";
import { FormSearchableField } from "./form-searchable-field";
import { Modal } from "./modal";
import { Label } from "./label";
import { AddAppointmentForm } from "../types/addAppointmentForm";
import { addLeadingZero } from "../util/add-leading-zero";
import { useState } from "react";
import { useOrganizers } from "../hooks/use-organizers";
import { FormDatepicker } from "./form-date-picker";
import { LocationFilter } from "./location-filter";
import { useLocations } from "../hooks/use-locations";
import { Option } from "../types/reactSelectOption";
import { TimeElement } from "./time-element";
import {
  checkInTimeLabel,
  checkOutTimeLabel,
  timeError,
  validateTime,
} from "../util/checkin-out-helper-functions";

type ModalAddVisitorProps = {
  title: string;
  submitButtonText: string;
  isAppointment?: boolean;
  onClose: () => void;
};

export const ModalAddVisitor = ({
  title,
  submitButtonText,
  isAppointment = false,
  onClose,
}: ModalAddVisitorProps) => {
  const { state, dispatch } = useAppData();
  const { data: clientLocations } = useLocations();
  const [locationFilter, setLocationFilter] = useState(
    localStorage.getItem("location") || ""
  );
  const { data: organizers } = useOrganizers();
  const now = `${addLeadingZero(new Date().getHours())}:${addLeadingZero(
    new Date().getMinutes()
  )}`;

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    getValues,
    formState: { errors, isDirty },
  } = useForm<AddAppointmentForm>({
    defaultValues: {
      date: state.date,
      checkInTime: isAppointment ? "" : now,
      checkOutTime: "",
      name: "",
      appointmentWithId: "",
      organization: "",
    },
  });

  const createVisit = useManagementCreateVisit({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_TOAST,
          payload: "Successfully created visit.",
        });
        onClose();
      },
      onError: (error) => {
        console.error("Error during check in:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Something went wrong during check in, please try again later.",
          },
        });
      },
    },
  });

  const submitForm: SubmitHandler<AddAppointmentForm> = (formData) => {
    if (!locationFilter || !formData) return;

    const checkInUtcTime = formatDateTime(formData.date, formData.checkInTime);
    const checkOutUtcTime = formatDateTime(
      formData.date,
      formData.checkOutTime
    );

    const data = {
      appointmentWith: formData.appointmentWithId,
      location: locationFilter,
      visitor: {
        name: formData.name,
        organization: formData.organization || undefined,
      },
      checkInTime: isAppointment ? undefined : checkInUtcTime,
      expectedCheckInTime: checkInUtcTime,
      expectedCheckOutTime: checkOutUtcTime,
    };
    createVisit.mutate(data);
  };

  const formatDateTime = (date = new Date(), time: string) => {
    if (!time) return;
    const today = date.toDateString();
    const dateTime = new Date(`${today} ${time}`);
    return dateTime.toISOString();
  };

  const handleSelectedLocation = (option: Option | null) => {
    if (!option) return;
    setLocationFilter(option.value);
  };

  //react-hook-form does not support warnings. To update warnings on input change, the values must be watched.
  const [checkInTime, checkOutTime] = watch(["checkInTime", "checkOutTime"]);

  return (
    <Modal
      className="w-1/2"
      show
      isManagementModal
      onClose={() => onClose()}
      children={
        <form
          className="overflow-hidden flex flex-col gap-6"
          onSubmit={handleSubmit(submitForm)}
        >
          <h5 className="font-medium mb-0">{title}</h5>
          <div className="flex flex-col gap-6">
            {isAppointment && (
              <div className="flex flex-col w-52">
                <Label htmlFor="date">Date</Label>
                <FormDatepicker<AddAppointmentForm>
                  name={"date"}
                  control={control}
                  initialDate={state.date || new Date()}
                />
              </div>
            )}
            {clientLocations && (
              <div className="flex flex-col gap-1 w-52">
                <Label htmlFor="location">Location</Label>
                <LocationFilter
                  clientLocations={clientLocations}
                  handleSelectedLocation={handleSelectedLocation}
                  value={locationFilter}
                />
              </div>
            )}
            <div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col w-52">
                  <Label htmlFor="checkInTime">
                    {checkInTimeLabel(isAppointment)}
                  </Label>
                  <TimeElement<AddAppointmentForm>
                    name={"checkInTime"}
                    control={control}
                    className="h-9"
                    rules={{
                      required: validationRules.checkInTime.required,
                      validate: (checkInTime) =>
                        validateTime(
                          checkInTime,
                          "checkOutTime",
                          clearErrors,
                          getValues
                        ),
                    }}
                  />
                </div>
                <div className="flex flex-col w-52 flex-end">
                  <Label htmlFor="checkOutTime">{checkOutTimeLabel()}</Label>
                  <TimeElement<AddAppointmentForm>
                    name={"checkOutTime"}
                    control={control}
                    className="h-9"
                    rules={{
                      validate: (checkOutTime) =>
                        validateTime(
                          checkOutTime,
                          "checkInTime",
                          clearErrors,
                          getValues
                        ),
                    }}
                  />
                </div>
              </div>
              {timeError(checkOutTime, checkInTime, errors, isDirty)}
            </div>
            <Input<AddAppointmentForm>
              isSmall
              label={"Full name *"}
              name={"name"}
              placeholder={"Full name"}
              rules={validationRules.nameManualInput}
              control={control}
            />
            <Input<AddAppointmentForm>
              isSmall
              label="Organization (optional)"
              name="organization"
              placeholder="Organization"
              control={control}
            />
            <FormSearchableField<AddAppointmentForm>
              label={"Appointment with *"}
              name={"appointmentWithId"}
              placeholder={"Select an organizer"}
              rules={validationRules.appointmentWith}
              control={control}
              options={organizers?.map((org: Organizer) => ({
                value: org.id,
                label: org.name,
              }))}
              menuPortalTarget={document.body}
              optionSelectionRequired={true}
            />
          </div>
          {!locationFilter && (
            <div className="mb-0 text-red">
              Currently no campus is selected, please select or reselect a
              campus.
            </div>
          )}
          <div className="flex justify-end gap-2 pt-2">
            <Button
              isSmall
              variant={"secondary"}
              data-testid="btn-check-in-out"
              isLoading={createVisit.isPending}
              onPointerDown={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              isSmall
              variant={"primary"}
              data-testid="btn-check-in-out"
              isLoading={createVisit.isPending}
            >
              {submitButtonText}
            </Button>
          </div>
        </form>
      }
    />
  );
};
