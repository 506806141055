export const validationRules = {
  appointmentWith: { required: "Please select an organizer" },
  appointmentWithId: { required: "Please select an organizer" },
  email: {
    required: "Please fill in your email",
    pattern: {
      //The email should not contain spaces, should have at least an @-symbol and
      //before and after the @-symbol should be at least one character.
      value: /^[^\s@]+@[^\s@]+\s*$/,
      message: "Invalid e-mail address",
    },
  },
  locationSelection: {
    required: "Please select a location",
  },
  nameSelection: {
    required: "Please select your name",
  },
  nameManualInput: {
    required: "Please fill in your full name",
    minLength: {
      value: 5,
      message: "Name must be at least 5 characters long",
    },
    pattern: {
      //The name should contain a word character followd by a space followd by a word character
      value: /\w+\s+\w+/,
      message: "Please fill in your first and lastname",
    },
  },
  registerClientName: {
    required: "Device name is required",
    maxLength: {
      value: 100,
      message:
        "The device name can not be longer than 100 characters in length.",
    },
  },
  checkInTime: {
    required: "Please select a start time",
    validate: (checkInTime: string, checkOutTime: string) =>
      checkInBeforeOut(checkInTime, checkOutTime),
  },
  checkOutTime: {
    validate: (checkInTime: string, checkOutTime: string) =>
      checkInBeforeOut(checkInTime, checkOutTime),
  },
};

const checkInBeforeOut = (checkInTime: string, checkOutTime: string) => {
  if (checkOutTime && checkInTime >= checkOutTime) {
    return "Check in time must be before check out time.";
  }
};
