import { AddAppointmentForm } from "../types/addAppointmentForm";
import { validationRules } from "./form-validation";
import { FieldErrors } from "react-hook-form";

export const validateTime = (
  time: string | Date | undefined,
  clearErrorsField: keyof AddAppointmentForm,
  clearErrors: (name: keyof AddAppointmentForm) => void,
  getValues: (name: string) => string
) => {
  if (!time || typeof time !== "string") return;
  clearErrors(clearErrorsField);

  if (clearErrorsField === "checkOutTime") {
    return validationRules.checkInTime.validate(
      time,
      getValues("checkOutTime")
    );
  }
  if (clearErrorsField === "checkInTime") {
    return validationRules.checkOutTime.validate(
      getValues("checkInTime"),
      time
    );
  }
};

export const timeError = (
  checkOutTime: string,
  checkInTime: string,
  errors: FieldErrors<AddAppointmentForm>,
  isDirty: boolean
) => {
  // We only show these errors when any of the input fields are dirty
  if (!isDirty) return null;

  let message = "";
  let messageColor = "text-secondary-blue";

  if (checkOutTime && outsideOfficeHours(checkOutTime)) {
    message = "Check out time is outside of office hours";
  }

  if (outsideOfficeHours(checkInTime)) {
    message = "Check in time is outside of office hours";
  }

  if (errors.checkInTime || errors.checkOutTime) {
    messageColor = "text-red";
    message = errors.checkInTime?.message || errors.checkOutTime?.message || "";
  }
  return <div className={`${messageColor} mb-0`}>{message}</div>;
};

export const checkInTimeLabel = (isAppointment: boolean) =>
  isAppointment ? "Expected check in time" : "Check in time";

export const checkOutTimeLabel = () => "Expected check out time";

export const outsideOfficeHours = (time: string) =>
  time < "08:00" || time > "18:00";
